// Based on OpenLayers and geoviewer-scaffolding

let DEBUG_PANEL = false;
// LITE_MODE to disable raster and other heavy layers by default for faster loading
let LITE_MODE = false;
// Fetch an auxilliary metadata file if metadata cannot be read from AVIF images
let METADATA_SRC_FOR_IMG = 'xmp';

import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/WebGLTile';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import XYZSource from 'ol/source/XYZ';
import {get as getProj, transform} from 'ol/proj';
import {register} from 'ol/proj/proj4';
import {Style, Stroke, Fill, Text, Icon} from 'ol/style';
import { Circle as CircleStyle } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import LinearRing from 'ol/geom/LinearRing';
import View from 'ol/View';
import { getCenter } from 'ol/extent';
import { defaults as interaction_defaults, DragPan, MouseWheelZoom, KeyboardZoom } from 'ol/interaction';
import exifr from 'exifr/src/bundles/lite.mjs';

import {
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from 'ol/geom';
import { defaults } from 'ol/control';
import FullScreen from 'ol/control/FullScreen';
import proj4 from 'proj4';
window.getProj = getProj;

let jsts = require('jsts');

proj4.defs('EPSG:3115', "+proj=tmerc +lat_0=4.596200416666666 +lon_0=-77.07750791666666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
register(proj4);

// Projección EPSG:3115: MAGNA-SIRGAS, zona Colombia Occidental
let EPSG_3115 = 'EPSG:3115';

// Projección EPSG:3857: Spherical Mercator
let EPSG_3857 = 'EPSG:3857';

// La resolución inicial se calcula basado en tamaño de la ventana
let MIN_RES_INICIAL = 40;

function calcResInicial() {
  let res = MIN_RES_INICIAL / (window.innerHeight / 768);
  return Math.min(MIN_RES_INICIAL, res);
}

let MAP_CENTER_3857 = [-8483000,330000];
let ZOOM_FACTOR = 1.5;
let MAX_ZOOM = 100;
let MIN_ZOOM = 5.5;
// Una base arbitraria para calcular estilos dinámicos
let RES_BASE = 40;
let FONT_NAME = 'Calibri,sans-serif';

let FORMAT_GEOJSON_3857 = new GeoJSON();
let FORMAT_GEOJSON_3115  = new GeoJSON({
  dataProjection: EPSG_3115,
  featureProjection: EPSG_3115,
});

// Ignore these attributes in the GeoJSON data when showing the attributes
// table
const IGNORE_ATTRIBUTES = {
  'geometry': 1,
  'id': 1,
  'Id': 1,
  'nombre': 1,
  'Point': 1,
  'point': 1,
  'fotos': 1,
  'VEREDA': 1,
  'vereda': 1,
  'RESGUARDO': 1,
}

let COLORES_SOLIDOS_6 = [
  'rgb(255, 0, 0, 1)',     // 0 rojo
  'rgb(255, 255, 0, 1)',   // 1 amarillo
  'rgb(160, 0, 160, 1)',   // 2 morado
  'rgb(0, 192, 0, 1)',     // 3 verde
  'rgb(0, 0, 255, 1)',     // 4 azul
  'rgb(196, 196, 196, 1)', // 5 gris
]

let COLORES_SEMITRANS_6 = [
  'rgb(255, 96, 96, 0.8)',     // 0 rojo
  'rgb(224, 224, 0, 0.8)',   // 1 amarillo
  'rgb(192, 32, 192, 0.8)',   // 2 morado
  'rgb(32, 192, 32, 0.8)',     // 3 verde
  'rgb(64, 64, 224, 0.8)',     // 4 azul
  'rgb(196, 196, 196, 0.7)', // 5 gris
]


let COLORES_TRANS_6 = [
  'rgb(255, 160, 160, 0.5)', // 0 rojo
  //'rgb(196, 196, 0, 0.4)', // 1 amarillo
  'rgb(196, 196, 0, 0.4)',   // 1 amarillo
  'rgb(255, 196, 255, 0.5)', // 2 morado
  'rgb(128, 255, 128, 0.5)', // 3 verde
  'rgb(192, 224, 255, 0.6)', // 4 azul
  'rgb(196, 196, 196, 0.7)', // 5 gris
]
let COLORES_SOLIDOS_10 = Array.prototype.concat(COLORES_SOLIDOS_6, [
  'rgb(256, 196, 0, 1)', // 6 anaranjado
  'rgb(64, 196, 255, 1)',     // 7 azul claro
  'rgb(128, 255, 128, 1)',     // 8 verde claro
  'rgb(0, 0, 0, 0, 1)', // 9 negro
])

let R = 0;  // rojo
let AM = 1; // amarillo
let M = 2;  // morado
let V = 3;  // verde
let AZ = 4; // azul
let G = 5;  // gris
let AN = 6; // anaranjado
let AZC = 7; // azul claro
let VC = 8; // verde claro
let N = 9; // negro


let DEPARTAMENTO_STROKE_COLORES = COLORES_SEMITRANS_6;
let DEPARTAMENTO_FILL_COLORES = COLORES_TRANS_6;
let TERRITORIO_FILL_COLORES = COLORES_TRANS_6;
let CUENCA_FILL_COLORES = COLORES_TRANS_6;

let DEPARTAMENTO_COLOR_ID = {
  "AMAZONAS": V,
  "ANTIOQUIA": R,
  "ARAUCA": V,
  "ATLANTICO": AM,
  "BOGOTA DC": G,
  "BOLIVAR": V,
  "BOYACA": M,
  "CALDAS": AM,
  "CAQUETA": AM,
  "CASANARE": AM,
  "CAUCA": V,
  "CESAR": M,
  "CHOCO": V,
  "CORDOBA": AM,
  "CUNDINAMARCA": AZ,
  "GUAINIA": V,
  "GUAVIARE": AZ,
  "HUILA": R,
  "LA GUAJIRA": V,
  "MAGDALENA": R,
  "META": V,
  "NARINO": R,
  "NORTE DE SANTANDER": R,
  "PUTUMAYO": M,
  "QUINDIO": V,
  "RISARALDA": AZ,
  "SAN ANDRES Y PROVIDENCIA": V,
  "SANTANDER": AM,
  "SUCRE": AZ,
  "TOLIMA": M,
  "VALLE DEL CAUCA": R,
  "VAUPES": M,
  "VICHADA": R,
}

let TERRITORIO_COLOR_ID = {
  "Tacueyó": AM,
  "Toribío": R,
  "San Francisco": V,
}

function mayusInicial(str) {
  const words = str.split(" ");
  return words.map((word) => { 
    return word[0].toUpperCase() + word.substring(1).toLowerCase(); 
  }).join(" ");
}

function departamentoStrokeColorFn(departamentoVal) {
  return DEPARTAMENTO_STROKE_COLORES[DEPARTAMENTO_COLOR_ID[departamentoVal]];
}

function departamentoFillColorFn(departamentoVal) {
  return DEPARTAMENTO_FILL_COLORES[DEPARTAMENTO_COLOR_ID[departamentoVal]];
}

function departamentoZIndexFn(departamentoVal) {
  let zIndex = 0;
  let colorId = DEPARTAMENTO_COLOR_ID[departamentoVal];
  if (departamentoVal === 'CAUCA') {
    zIndex = 3;
  } else if (colorId === R) {
    zIndex = 2;
  } else if (colorId === V) {
    zIndex = 1;
  }
  return zIndex;
}

function municipioStrokeColorFn(departamento) {
  return DEPARTAMENTO_STROKE_COLORES[DEPARTAMENTO_COLOR_ID[departamento]];
}

function municipioFillColorFn(departamento) {
  return DEPARTAMENTO_FILL_COLORES[DEPARTAMENTO_COLOR_ID[departamento]];
}

function territorioFillColorFn(territorio) {
  return TERRITORIO_FILL_COLORES[TERRITORIO_COLOR_ID[territorio]];
}

function makeFont(tamanoPx) {
  return tamanoPx + 'px ' + FONT_NAME;
}

function makeBoldFont(tamanoPx) {
  return 'bold ' + tamanoPx + 'px ' + FONT_NAME;
}

function makeAngle(grados) {
  return Math.PI * (grados/180);
}

function getMaxPoly(polys) {
  // Buscar poligono más grande en multi-poligono para no repetir etiqueta
  var polyObj = [];
  for (var b = 0; b < polys.length; b++) {
    polyObj.push({ poly: polys[b], area: polys[b].getArea() });
  }
  polyObj.sort(function (a, b) { return a.area - b.area });

  return polyObj[polyObj.length - 1].poly;
}

// Estilos para fondos oscuros
let estilosOscuro = {
  fondo: {
     fillColor: 'rgba(128, 176, 112, 1)',
  },

  colombia: {
    minResolution: 5000,
    strokeColor: 'rgba(0, 255, 0, 1)',
    strokeWidth: 2,
  },

  departamento: {
    mostrarRelleno: false,
    minResolution: 300,
    maxResolution: 5000,
    strokeWidth: 2,
    fillColor: 'rgba(0, 0, 0, 0)',
    geometry: function(feature){
      let pt;
      if (feature.getGeometry().getType() === 'MultiPolygon') {
        pt =  getMaxPoly(feature.getGeometry().getPolygons()).getInteriorPoint();
      } else if (feature.getGeometry().getType() === 'Polygon') {
        pt = feature.getGeometry().getInteriorPoint();
      }
      return pt;
    },
    text: {
      minResolution: 300,
      minResolutionPequeno: 800,
      maxResolution: 3000,
      maxResolutionOverflow: 2000,
      font: makeBoldFont(24),
      fontPequeno: makeBoldFont(18),
      fillColor: 'rgba(255, 255, 255, 1)',
      strokeColor: 'rgba(0, 0, 0, 1)',
      strokeWidth: 4,
      overflow: true,
      transform: {
        'Amazonas': {
          offsetX: 0,
          offsetY: -2000,
        },
        'Antioquia': {
          offsetX: 0,
          offsetY: 1000,
        },
        'Bogotá D.C.': {
          offsetX: 2000,
          offsetY: 0,
        },
        'Cundinamarca': {
          offsetX: 0,
          offsetY: -500,
        },
        'Cauca': {
          offsetX: 0,
          offsetY: -1000,
        },
        'Chocó': {
          offsetX: 1000,
          offsetY: 1500,
        },
        'Valle del Cauca': {
          offsetX: -1500,
          offsetY: 1000,
        },
        'Risaralda': {
          offsetX: -500,
          offsetY: 500,
        },
        'Tolima': {
          offsetX: 0,
          offsetY: 1000,
        },
        'Vaupés': {
          offsetX: 1000,
          offsetY: -1000,
        },
      },
    },
  },

  municipio: {
    mostrarRelleno: false,
    minResolution: 0,
    maxResolution: 300,
    maxResolutionHigh: 140,
    strokeColor: COLORES_SOLIDOS_6[DEPARTAMENTO_COLOR_ID["CAUCA"]],
    // Se usa un color crema para cuando la resolución es alta
    strokeColorHighRes: 'rgba(220, 200, 200, 1)',
    // strokeColor por defecto
    strokeColorLowResDefault: 'rgba(220, 220, 220, 1)',
    strokeWidth: 2.5,
    fillColor: 'rgba(0, 0, 0, 0)',
    zIndex: {
      Planadas: 2,
      Inza: 2,
    },
    text: {
      minResolution: 0,
      minResolutionToribio: 30,
      maxResolution: 300,
      maxResolutionOverflow: 160,
      maxResolutionTransform: 100,
      font: makeFont(28),
      fillColor: 'rgba(255, 255, 255, 1)',
      strokeColor: 'rgba(0, 0, 0, 1)',
      strokeWidth: 2,
      overflow: true,
      transform: {
        'CALOTO': {
          offsetX: -115,
          offsetY: 20,
        },
        'PLANADAS': {
          offsetX: -160,
          offsetY: 360,
        },
        'JAMBALO': {
          offsetX: 190,
          offsetY: 120,
        },
        'SILVIA': {
          offsetX: 280,
          offsetY: 15,
        },
        'INZA': {
          offsetX: 50,
          offsetY: -200,
        },
        'LA PLATA': {
          offsetX: 120,
          offsetY: -380,
        },
        'TESALIA': {
          offsetX: -110,
          offsetY: -10,
        },
        'PAICOL': {
          offsetX: -220,
          offsetY: -100,
        },
        'IQUIRA': {
          offsetX: -130,
          offsetY: -70,
        },
        'TOTORO': {
          offsetX: 300,
          offsetY: -60,
        },
      },
    },
  },

  territorio: {
    mostrarRelleno: false,
    maxResolution: 140,
    maxResolutionHigh: 15,
    strokeColor: 'rgba(255, 152, 0, 1)',
    strokeWidth: 3.5,
    strokeWidthHighRes: 5,
    text: {
      maxResolution: 140,
      minResolution: 15,
      minResolutionPequeno: 60,
      maxResolutionCabecera: 40,
      minResolutionMasPequeno: 120,
      //font depende del territorio y resolución
      font: makeBoldFont(24),
      fontPequeno: makeBoldFont(18),
      fontMasPequeno: makeBoldFont(16),
      font2: makeBoldFont(24),
      font2Pequeno: makeBoldFont(16),
      font2MasPequeno: makeBoldFont(12),
      fontCabecera: makeFont(20),
      fillColor: 'rgba(255, 255, 255, 1)',
      strokeColor: 'rgba(0, 0, 0, 1)',
      strokeWidth: 5,
      overflow: true,
      zIndexCabecera: -1,
      transform: {
        'San Francisco': {
          offsetX: 50,
          offsetY: 100,
        },
      },
    },
  },

  vereda: {
    maxResolution: 30,
    //strokeColor: 'rgba(255, 0, 0, 0.7)',
    //strokeColor: 'rgba(255, 0, 0, 0.5)',
    strokeColor: 'rgba(255, 255, 0, 0.5)',
    strokeWidth: 2,
    text: {
      maxResolution: 30,
      maxResolutionOverflow: 16,
      maxResolutionPequeno: 10,
      font: makeFont(24),
      fontPequeno: makeFont(20),
      fontCabecera: makeFont(16),
      fillColor: 'rgba(255, 255, 255, 1)',
      strokeColor: 'rgba(0, 0, 0, 1)',
      strokeWidth: 3,
      overflow: true,
      offsetXDefault: 0,
      offsetYDefault: 0,
      transform: {
      },
    },
  },
  drenajeLinea: {
    maxResolution: 60,
    strokeColor: 'rgba(50, 50, 255, 1)',
    strokeWidthBase: 0.8,
  },
  paramo: {
    maxResolution: 140,
    strokeColor: 'rgba(255, 255, 255, 1)',
    strokeWidth: 3,
    fillColor: 'rgba(192, 192, 192, 0.4)',
    text: {
      maxResolution: 140,
      maxResolutionOverflow: 30,
      font: makeFont(24),
      fillColor: 'rgba(224, 224, 224, 1)',
      strokeColor: 'rgba(0, 0, 0, 1)',
      strokeWidth: 3,
      offsetY: -4,
    },
  },
  parque: {
    maxResolution: 140,
    strokeColor: 'rgba(0, 255, 0, 1)',
    strokeWidth: 3,
    fillColor: 'rgba(0, 224, 0, 0.4)',
    text: {
      maxResolution: 140,
      maxResolutionOverflow: 30,
      font: makeFont(24),
      fillColor: 'rgba(244, 255, 224, 1)',
      strokeColor: 'rgba(0, 0, 0, 1)',
      strokeWidth: 3,
      offsetX: -200,
      offsetY: 50,
    },
  },
  reserva: {
    maxResolution: 30,
    strokeColor: 'rgba(128, 0, 255, 0.5)',
    strokeWidth: 3,
    fillColor: 'rgba(0, 0, 255, 0.4)',
    radius: 6,
    geometry: function(feature){
      let pt;
      if (feature.getGeometry().getType() === 'MultiPolygon') {
	pt = feature.getGeometry().getPolygons()[0].getInteriorPoint();
      } else if (feature.getGeometry().getType() === 'Polygon') {
        pt = feature.getGeometry().getInteriorPoint();
      }
      return pt;
    },
    text: {
      maxResolution: 10,
      maxResolutionOverflow: 10,
      maxResolutionBigFont: 3,
      fontNormal: makeFont(16),
      fontBig: makeFont(20),
      fillColor: 'rgba(255, 255, 255, 1)',
      strokeColor: 'rgba(0, 0, 0, 1)',
      strokeWidth: 3,
      offsetX: 0,
      offsetYNormalFont: 3,
      offsetYBigFont: 2,
    },
    icon: {
      maxResolutionSmallIcon: 15,
      maxResolutionNormalIcon: 8,
      maxResolutionBigIcon: 3,
      offsetYSmallIcon: -6.8,
      offsetYNormalIcon: 0,
      offsetYBigIcon: 0,
      // Special offset for Caracol
      transform: {
        'Reserva Caracol': {
          offsetYSmallIcon: -10,
        },
      },
    },
  },
  riego: {
    maxResolution: 20,
    strokeColor: 'rgba(255, 255, 255, 1)',
    bufferStrokeColor: 'rgba(0, 0, 255, 0.5)',
    strokeWidth: 2,
    fillColor: 'rgba(0, 0, 255, 1)',
    geometry: function(feature){
      if (feature.getGeometry().getType() === 'MultiLineString') {
	let extent = feature.getGeometry().getExtent();
	return new Point(getCenter(extent));
      }
      return feature.getGeometry();
    },
    text: {
      maxResolution: 8,
      maxResolutionBigFont: 4,
      fontNormal: makeFont(14),
      fontBig: makeFont(20),
      fillColor: 'rgba(255, 255, 255, 1)',
      strokeColor: 'rgba(0, 0, 255, 1)',
      strokeWidth: 2,
      textBaseline: 'bottom',
      offsetY: -5,
      overflow: true,
    },
    icon: {
      maxResolutionSmallIcon: 15,
      maxResolutionNormalIcon: 8,
      maxResolutionBigIcon: 3,
      offsetYSmallIcon: -3,
      offsetYNormalIcon: -2,
      offsetYBigIcon: -1,
      transform: {},
    },
  },
  beneficiarioWFP: {
    maxResolution: 24,
    strokeColor: 'rgba(255, 255, 255, 1)',
    strokeWidth: 3,
    fillColor: 'rgba(82, 137, 195, 1)',
    radius: 20,
    text: {
      maxResolution: 24,
      font: makeBoldFont(22),
      fillColor: 'rgba(255, 255, 255, 1)',
      textBaseline: 'top',
      overflow: true,
      offsetY: -12,
    },
  },
  centroPoblado: {
    strokeColor: 'rgba(255, 128, 0, 0.6)',
    strokeWidth: 2,
    maxResolution: 30,
    maxResolutionGrande: 15,
    transparency: 0.6,
    transparencyGrande: 0.4,
    fillColorFn: function(trans) { 
      return 'rgba(255, 128, 0, ' + trans + ')';
    },
    //fillColor es calculado
    geometry: function(feature){
      let pt;
      if (feature.getGeometry().getType() === 'MultiPolygon') {
        pt =  getMaxPoly(feature.getGeometry().getPolygons()).getInteriorPoint();
      } else if (feature.getGeometry().getType() === 'Polygon') {
        pt = feature.getGeometry().getInteriorPoint();
      }
      return pt;
    },
    text: {
      maxResolution: 11,
      font: makeFont(24),
      fontPequeno: makeFont(16),
      strokeColor: 'rgba(255, 0, 0, 1)',
      strokeWidth: 1,
      fillColor: 'rgba(255, 255, 255, 1)',
      textBaseline: 'bottom',
      offsetXDefault: 0,
      offsetYDefault: -2,
      textAlignDefault: 'left',
      overflow: true,
      transform: {
      },
    },
  },
  via: {
    strokeColor: 'rgb(208, 208, 144, 1)',
    strokeWidth: 2,
    maxResolution: 60,
  },
  viaUnclassified: {
    strokeColor: 'rgb(192, 192, 128, 1)',
    strokeWidth: 1,
    maxResolution: 60,
  },
};

function clonar(obj) {
  let clon = {};
  for (var k in obj) {
    if (typeof(obj[k]) === 'object') {
      clon[k] = clonar(obj[k])
    } else {
      clon[k] = obj[k];
    }
  }
  return clon;
}
window.clonar = clonar;

// Estilos para fondos claros
let estilosClaro = clonar(estilosOscuro);
estilosClaro.fondo.fillColor = 'rgba(225, 207, 140, 1)';
estilosClaro.departamento.text.fillColor = 'rgba(0, 0, 0, 1)';
estilosClaro.departamento.text.strokeColor = 'rgba(255, 255, 255, 1)';
estilosClaro.municipio.text.fillColor = 'rgba(0, 0, 0, 1)';
estilosClaro.municipio.text.strokeColor = 'rgba(255, 255, 255, 1)';
estilosClaro.territorio.strokeColor = 'rgba(0, 0, 0, 1)';
estilosClaro.territorio.strokeWidth = 3;
estilosClaro.territorio.strokeWidthPequeno = 6;
estilosClaro.territorio.text.fillColor = 'rgba(0, 0, 0, 1)';
estilosClaro.territorio.text.strokeColor = 'rgba(255, 255, 255, 1)';
estilosClaro.via.strokeColor = 'rgb(64, 64, 64, 1)';
estilosClaro.via.strokeWidth = 2.5;
estilosClaro.vereda.strokeColor = 'rgb(128, 112, 80, 1)';
estilosClaro.vereda.strokeWidth = 5;
estilosClaro.vereda.text.fillColor = 'rgba(0, 0, 0, 1)';
estilosClaro.vereda.text.strokeColor = 'rgba(255, 255, 255, 1)';
estilosClaro.centroPoblado.transparency = 0.8;
estilosClaro.centroPoblado.transparencyGrande = 0.8;
estilosClaro.centroPoblado.text.fillColor = 'rgba(0, 0, 0, 1)';
estilosClaro.centroPoblado.text.strokeColor = 'rgba(255, 144, 0, 1)';

let estilosNeutral = clonar(estilosClaro);
estilosNeutral.centroPoblado.text.font = makeBoldFont(24);
estilosNeutral.centroPoblado.text.fontPequeno = makeBoldFont(16);
estilosNeutral.centroPoblado.text.strokeColor = 'rgba(255, 255, 255, 1)';
estilosNeutral.centroPoblado.text.strokeWidth = 3;

// Estilos actuales, que pueden cambiar dependiendo si el fondo es claro o oscuro
let estilos = estilosOscuro;

let fondoStyleFn = function(feature, res) {
  let e = estilos.fondo;
  let fondoStyle = new Style({
    fill: new Fill({
      color: e.fillColor,
    }),
  });
  return fondoStyle;
};

let layerTree = function(options) {
  this.map = options.map;
  let containerDiv = document.getElementById(options.target);
  let colapsado = false;
  let legendItemsDiv = document.getElementById('legenditems');
  let legendDiv = document.getElementById('legend');
  let legendColapsado = false;

  let buttonLayerTreeCollapse = document.getElementById('layertreecollapse');
  let buttonLegendCollapse = document.getElementById('legendcollapse');

  this.layerContainer = document.createElement('div');
  this.layerContainer.className = 'layercontainer';
  containerDiv.appendChild(this.layerContainer);

  let title = document.createElement('h3');
  title.className = 'title';
  title.textContent = 'Kiwe Piisanxi';
  this.layerContainer.appendChild(title);

  let layerRasterHeader = document.createElement('h4');
  layerRasterHeader.className = 'layerheader';
  layerRasterHeader.textContent = 'Fondos';
  this.layerContainer.appendChild(layerRasterHeader);

  this.layerContainerRaster = document.createElement('div');
  this.layerContainerRaster.className = 'layercontainerraster';
  this.layerContainer.appendChild(this.layerContainerRaster);

  let layerVectorHeader = document.createElement('h4');
  layerVectorHeader.className = 'layerheader';
  layerVectorHeader.textContent = 'Capas';
  this.layerContainer.appendChild(layerVectorHeader);

  this.layerContainerVector = document.createElement('div');
  this.layerContainerVector.className = 'layercontainervector';
  this.layerContainer.appendChild(this.layerContainerVector);

  let layerContainerFooter = document.getElementById('layercontainerfooter');

  let version = document.createElement('div');
  version.textContent = 'Actualizado 11/29/2023';
  layerContainerFooter.appendChild(version);

  let fuentes = document.createElement('div');
  fuentes.textContent = 'Fuentes: IGAC, OpenStreetMap, Sentinel-2 (Comisión Europea), NASA, Proyecto Nasa';
  fuentes.className = 'fuentes';
  layerContainerFooter.appendChild(fuentes);
  let elaborado_por = document.createElement('div');
  elaborado_por.innerHTML = 'Elaborado por: <a href="https://www.sekinfo.org">sekinfo.org</a>';
  elaborado_por.className = 'elaborado-por';
  layerContainerFooter.appendChild(elaborado_por);

  buttonLayerTreeCollapse.addEventListener('click', function() {
    let mapElem = document.getElementById('map');
    if (colapsado) {
      buttonLayerTreeCollapse.innerHTML = '<div class="tri">◀</div><div class="bar">|</div>';
      buttonLayerTreeCollapse.classList.remove('collapsed');
      containerDiv.classList.remove('collapsed');
      legendDiv.classList.remove('layertreecollapsed');
      mapElem.classList.remove('full');
      map.updateSize();
      colapsado = false;
      layerContainerFooter.style = 'display: block';
    } else {
      buttonLayerTreeCollapse.innerHTML = '<div class="bar">|</div><div class="tri">▶</div>';
      buttonLayerTreeCollapse.classList.add('collapsed');
      containerDiv.classList.add('collapsed');
      legendDiv.classList.add('layertreecollapsed');
      mapElem.classList.add('full');
      map.updateSize();
      colapsado = true;
      layerContainerFooter.style = 'display: none';
      document.getElementById('map').focus();
    }
  });

  buttonLegendCollapse.addEventListener('click', function() {
    let mapElem = document.getElementById('map');
    if (legendColapsado) {
      buttonLegendCollapse.innerHTML = '<div class="tri">◀</div><div class="bar">|</div>';
      buttonLegendCollapse.classList.remove('collapsed');
      legendItemsDiv.classList.remove('collapsed');
      map.updateSize();
      legendColapsado = false;
    } else {
      buttonLegendCollapse.innerHTML = '<div class="bar">|</div><div class="tri">▶</div>';
      buttonLegendCollapse.classList.add('collapsed');
      legendItemsDiv.classList.add('collapsed');
      map.updateSize();
      legendColapsado = true;
      document.getElementById('map').focus();
    }
  });

  let idCounter = 0;
  this.createRegistry = function(layer, active) {
    layer.set('id', 'layer_', + idCounter);
    layer.set('active', active);
    layer.set('expanded', false);
    layer.set('etiquetas', true);
    layer.set('icons', true);
    idCounter += 1;
    let layerDiv = document.createElement('div');
    layer.set('layerDiv', layerDiv);
    layerDiv.className = 'layer ol-unselectable';
    if (active) {
      layerDiv.classList.add('active');
    }
    layerDiv.name = layer.get('name') || 'sin nombre';
    layerDiv.id = layer.get('id');
    let layerNameContainer = document.createElement('div');
    layerNameContainer.className = 'layernamecontainer';
    layerDiv.appendChild(layerNameContainer);
    let layerExpand, container;
    if (layer.get('orden') > 2) {
      container = this.layerContainerVector;
      layerExpand = document.createElement('span');
      layerExpand.className = 'layerexpand';
      layerExpand.textContent = '+';
      layerNameContainer.appendChild(layerExpand);
    } else {
      container = this.layerContainerRaster;
      layerExpand = document.createElement('span');
      layerExpand.className = 'layerexpand';
      layerExpand.textContent = '+';
      layerNameContainer.appendChild(layerExpand);
      //layerNameContainer.classList.add('layerraster');
    }
    let layerName = document.createElement('span');
    layerName.className = 'layername';
    layerName.textContent = layerDiv.name;
    layerNameContainer.appendChild(layerName);

    // Opciones - solo para capas vector
    let layerOpcionesContainer, layerEtiquetas, layerIcons, layerMostrarSiempre, layerRelleno, layerFuente;
    //if (layer.get('orden') > 2) {
    if (true) {
      layerOpcionesContainer = document.createElement('div');
      layerOpcionesContainer.className = 'layeroptionscontainer collapsed';
      layerDiv.appendChild(layerOpcionesContainer);

      if (layer.get('textLayers')) {
        layerEtiquetas = document.createElement('div');
        layerEtiquetas.className = 'layeroption layerhide active';
        layerEtiquetas.textContent = 'Mostrar Etiquetas';
        layerOpcionesContainer.appendChild(layerEtiquetas);
      }

      if (layer.get('iconLayers')) {
        layerIcons = document.createElement('div');
        layerIcons.className = 'layeroption layerhide active';
        layerIcons.textContent = 'Mostrar Íconos';
        layerOpcionesContainer.appendChild(layerIcons);
      }

      if (layer.get('orden') > 2) {
        layerMostrarSiempre = document.createElement('div');
        layerMostrarSiempre.className = 'layeroption layershowalways';
        layerMostrarSiempre.textContent = 'Mostrar Siempre';
        layerOpcionesContainer.appendChild(layerMostrarSiempre);
      }

      if (layer.get('relleno')) {
        layerRelleno = document.createElement('div');
        layerRelleno.className = 'layeroption layerrelleno';
        layerRelleno.textContent = 'Mostrar Relleno';
        layerOpcionesContainer.appendChild(layerRelleno);
        let estilos = layer.get('getStyles')();
        if (estilos.mostrarRelleno) {
          layerRelleno.classList.add('active');
        }
      }
      if (layer.get('fuente')) {
        layerFuente = document.createElement('div');
        layerFuente.className = 'layeroption layerfuente';
        layerFuente.textContent = 'Fuente: ' + layer.get('fuente');
        layerOpcionesContainer.appendChild(layerFuente);
      }
    }

    container.insertBefore(layerDiv, container.firstChild);
    layerName.addEventListener('click', function() {
      let textLayers = layer.get('textLayers') || [];
      let iconLayers = layer.get('iconLayers') || [];
      let legendItem = layer.get('legendItem');
      if (layer.get('active')) {
        layer.set('active', false);
	layerDiv.classList.remove('active');
        map.removeLayer(layer);
        if (textLayers) {
	  for (var l of textLayers) {
            map.removeLayer(l);
          }
        }
        if (iconLayers) {
	  for (var l of iconLayers) {
            map.removeLayer(l);
          }
        }
        if (legendItem) {
	  redrawLegend();
	}
      } else {
        layer.set('active', true);
	layerDiv.classList.add('active');
        let orden = layer.get('orden');
	let layers = map.getLayers();
	let index = layers.getArray().filter(l => l.get('orden') < orden).length;
	if (orden === 1 || orden === 2) {
	  layers.getArray().filter(l => l.get('orden') === orden).forEach(l => {
            let textLayers = layer.get('textLayers');
            if (l.get('active')) {
              l.set('active', false);
	      l.get('layerDiv').classList.remove('active');
              map.removeLayer(l);
              if (textLayers) {
	        for (var l of textLayers) {
                  map.removeLayer(l);
                }
              }
            }
          });
          if (estilos !== layer.get('estilos')) {
            estilos = layer.get('estilos');
	    layers.forEach(l => l.changed());
            redrawLegend();
	  }
        }
        layers.insertAt(index, layer);
	if (textLayers && layer.get('etiquetas')) {
	  for (var l of textLayers) {
            let orden = l.get('orden');
	    let indexText = layers.getArray().filter(l => l.get('orden') < orden).length;
            layers.insertAt(indexText, l);
          }
	  for (var l of iconLayers) {
            map.addLayer(l);
          }
        }
        if (legendItem) {
	  redrawLegend();
	}
      }
    });
    //if (layer.get('orden') > 2) {
    if (true) {
      layerExpand.addEventListener('click', function() {
        if (layer.get('expanded')) {
          layer.set('expanded', false);
          layerOpcionesContainer.classList.remove('expanded');
        } else {
          layer.set('expanded', true);
          layerOpcionesContainer.classList.add('expanded');
        }
      });
      if (layer.get('textLayers')) {
        layerEtiquetas.addEventListener('click', function() {
          let textLayers = layer.get('textLayers');
          if (layer.get('etiquetas')) {
            layer.set('etiquetas', false);
            layerEtiquetas.classList.remove('active');
	    for (var l of textLayers) {
              map.removeLayer(l);
            }
          } else {
            layer.set('etiquetas', true);
            layerEtiquetas.classList.add('active');
            if (textLayers) {
	      for (var l of textLayers) {
                let layers = map.getLayers();
                let orden = l.get('orden');
                let index = layers.getArray().filter(l => l.get('orden') < orden).length;
                layers.insertAt(index, l);
              }
            }
          }
        });
      }
      if (layer.get('iconLayers')) {
        layerIcons.addEventListener('click', function() {
          let iconLayers = layer.get('iconLayers');
          if (layer.get('icons')) {
            layer.set('icons', false);
            layerIcons.classList.remove('active');
	    for (var l of iconLayers) {
              map.removeLayer(l);
            }
          } else {
            layer.set('icons', true);
            layerIcons.classList.add('active');
            if (iconLayers) {
	      for (var l of iconLayers) {
                //let layers = map.getLayers();
                //let orden = l.get('orden');
                //let index = layers.getArray().filter(l => l.get('orden') < orden).length;
                //layers.insertAt(index, l);
                map.addLayer(l);
              }
            }
          }
        });
      }
      if (layer.get('orden') > 2) {
        layerMostrarSiempre.addEventListener('click', function() {
          let estilos = layer.get('getStyles')();
          let textLayers = layer.get('textLayers');
          let iconLayers = layer.get('iconLayers');
	  let auxLayers = [].concat(textLayers,iconLayers);
          if (estilos.mostrarSiempre) {
            estilos.mostrarSiempre = false;
            layer.changed();
            if (auxLayers) {
              for (var l of auxLayers) {
                let estilosAux = l.get('getStyles')();
                estilosAux.mostrarSiempre = false;
                l.changed();
              }
            }
            layerMostrarSiempre.classList.remove('active');
          } else {
            estilos.mostrarSiempre = true;
            layer.changed();
            if (auxLayers) {
              for (var l of auxLayers) {
                let estilosAux = l.get('getStyles')();
                estilosAux.mostrarSiempre = true;
                l.changed();
              }
            }
            layerMostrarSiempre.classList.add('active');
          }
	  redrawLegend();
        });
      }
      if (layerRelleno) {
        layerRelleno.addEventListener('click', function() {
          let estilos = layer.get('getStyles')();
          if (estilos.mostrarRelleno) {
            estilos.mostrarRelleno = false;
            layer.changed();
            layerRelleno.classList.remove('active');
          } else {
            estilos.mostrarRelleno = true;
            layer.changed();
            layerRelleno.classList.add('active');
          }
        });
      }
    }
    return this;
  };
  this.layerContainerVector.appendChild(document.createElement('br'))
  this.layerContainerVector.appendChild(document.createElement('br'))
};

let colombiaStyleFn = function(feature, res) {
  let e = estilos.colombia;
  if (res >= e.minResolution || e.mostrarSiempre) {
    let strokeColor = e.strokeColor;

    let colombiaStyle = new Style({
      stroke: new Stroke({
        color: strokeColor,
        width: e.strokeWidth,
      }),
    });
    return colombiaStyle;
  }
};

let getDepartamentoText = function(feature) {
  let departamento = feature.values_.departamento;
  if (departamento == 'Valle del Cauca') {
    return 'Valle del\nCauca';
  } else if (departamento == 'Norte de Santander') {
    return 'Norte de\nSantander';
  }
  return departamento;
};

let departamentoText = function(feature, res) {
  let e = estilos.departamento.text;
  if ((res >= e.minResolution && res <= e.maxResolution) || e.mostrarSiempre) {
    let departamento = feature.values_.departamento;
    let overflow = true;
    if (res >= e.maxResolutionOverflow) {
      overflow = false;
    }
    let trans = e.transform[departamento] || {};
    let offsetX = trans.offsetX || 0;
    let offsetY = trans.offsetY || 0;
    let font = e.font;
    if (res >= e.minResolutionPequeno) {
      font = e.fontPequeno;
    }

    return new Text({
      text: getDepartamentoText(feature),
      font: font,
      fill: new Fill({
        color: e.fillColor,
      }),
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      offsetY: offsetY*(RES_BASE/res),
      offsetX: offsetX*(RES_BASE/res),
      overflow: overflow,
    })
  }
};

let departamentoStyleFn = function(feature, res) {
  let e = estilos.departamento;
  if ((res >= e.minResolution && res <= e.maxResolution) || e.mostrarSiempre) {
    let departamento = feature.values_.departamento;
    let departamentoVal = feature.values_.val;
    let strokeColor = departamentoStrokeColorFn(departamentoVal);
    let zIndex = departamentoZIndexFn(departamentoVal);

    let departamentoStyleObj = {
      stroke: new Stroke({
        color: 'rgb(0, 0, 0, 1)',
        width: e.strokeWidth * (1 - res/3000),
	offsetY: e.offsetY || 0,
      }),
      zIndex: zIndex,
    };
    if (e.mostrarRelleno) {
      let fillColor = departamentoFillColorFn(departamentoVal);
      departamentoStyleObj['fill'] = new Fill({
        color: fillColor,
      });
    }
    let departamentoStyle = new Style(departamentoStyleObj);
    
    return departamentoStyle;
  }
};

let departamentoBufferStyleFn = function(feature, res) {
  let e = estilos.departamento;
  if ((res >= e.minResolution && res <= e.maxResolution) || e.mostrarSiempre) {
    let departamento = feature.values_.departamento;
    let departamentoVal = feature.values_.val;
    let strokeColor = departamentoStrokeColorFn(departamentoVal);

    let departamentoBufferStyleObj = {
      stroke: new Stroke({
        color: strokeColor,
        width: Math.max(6 * (1 - res/6000), 4),
      }),
    };
    let departamentoBufferStyle = new Style(departamentoBufferStyleObj);
    
    return departamentoBufferStyle;
  }
};


let departamentoTextStyleFn = function(feature, res) {
  let e = estilos.departamento;
  let departamentoTextStyle = new Style({
    text: departamentoText(feature, res),
    geometry: e.geometry(feature),
  });
  return departamentoTextStyle;
};


let getMunicipioText = function(feature) {
  return mayusInicial(feature.values_.MPIO_CNMBR);
};

let municipioText = function(feature, res) {
  let e = estilos.municipio.text;
  if ((res >= e.minResolution && res <= e.maxResolution) || e.mostrarSiempre) {
    let municipio = feature.values_.MPIO_CNMBR;
    let overflow = true;
    if (res >= e.maxResolutionOverflow) {
      overflow = false;
    }
    if (municipio === 'TORIBÍO' && res < e.minResolutionToribio) {
      return;
    }
    let departamentoVal = feature.values_.DPTO_CNMBR;
    let strokeColor = departamentoStrokeColorFn(departamentoVal);
    let trans = {};
    if (res <= e.maxResolutionTransform) {
      trans = e.transform[municipio] || {};
    }
    let offsetX = trans.offsetX || 0;
    let offsetY = trans.offsetY || 0;
   
    return new Text({
      text: getMunicipioText(feature),
      font: e.font,
      fill: new Fill({
        color: e.fillColor,
      }),
      stroke: new Stroke({
        color: strokeColor,
        width: e.strokeWidth,
      }),
      offsetY: offsetY*(RES_BASE/res),
      offsetX: offsetX*(RES_BASE/res),
      overflow: overflow,
    })
  }
};

let municipioStyleFn = function(feature, res) {
  let e = estilos.municipio;
  if ((res >= e.minResolution && res <= e.maxResolution) || e.mostrarSiempre) {
    let departamentoVal = feature.values_.DPTO_CNMBR;

    // Usar un color dependiendo de la resolución
    let strokeColor = departamentoStrokeColorFn(departamentoVal);
    if (res < e.maxResolutionHigh) {
      strokeColor = departamentoStrokeColorFn(departamentoVal);
    }

    let municipio = feature.values_.MPIO_CNMBR;

    let zIndex = 1;
    if (departamentoVal === 'CAUCA') {
      zIndex = 2;
    }
    if (municipio === 'TORIBÍO') {
      zIndex = 3;
    }
    zIndex = e.zIndex[municipio] || zIndex;
    
    let municipioStyleObj = {
      stroke: new Stroke({
        color: strokeColor,
        width: e.strokeWidth,
      }),
      zIndex: zIndex,
    };
    if (e.mostrarRelleno) {
      let fillColor = municipioFillColorFn(departamentoVal);
      municipioStyleObj['fill'] = new Fill({
        color: fillColor,
      });
    }
    let municipioStyle = new Style(municipioStyleObj);
    return municipioStyle;
  }
};

let municipioTextStyleFn = function(feature, res) {
  let municipioTextStyle = new Style({
    text: municipioText(feature, res),
  });
  return municipioTextStyle;
};

let getTerritorioText = function(feature) {
  return feature.values_.territorio;
};

let territorioText = function(feature, res) {
  let e = estilos.territorio.text;
  if ((res >= e.minResolution && res <= e.maxResolution) || e.mostrarSiempre) {
    let territorio = feature.values_.territorio;
    let font = e.font;
    let fontCabecera = e.fontCabecera;
    if (res >= e.minResolutionPequeno) {
      font = e.fontPequeno;
      if (res >= e.minResolutionMasPequeno) {
        font = e.fontMasPequeno;
      }
    }
    let trans = e.transform[territorio] || {};
    let offsetX = trans.offsetX || 0;
    let offsetY = trans.offsetY || 0;
    // Casos especial para territorios específicos:
    // Desplazar etiqueta según resolución
    return new Text({
      text: getTerritorioText(feature),
      font: font,
      fill: new Fill({
        color: e.fillColor,
      }),
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      offsetY: offsetY*(RES_BASE/res),
      offsetX: offsetX*(RES_BASE/res),
      overflow: e.overflow,
    })
  }
};

let territorioStyleFn = function(feature, res) {
  let e = estilos.territorio;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let strokeWidth = e.strokeWidth;
    if (res <= e.maxResolutionHigh) {
      strokeWidth = e.strokeWidthHighRes;
    }
    let territorioStyleObj = {
      stroke: new Stroke({
          color: e.strokeColor,
          width: strokeWidth,
      }),
    };
    if (e.mostrarRelleno) {
      let fillColor = territorioFillColorFn(feature.values_.territorio);
      territorioStyleObj['fill'] = new Fill({
        color: fillColor,
      });
    }
    let territorioStyle = new Style(territorioStyleObj);
    return territorioStyle;
  }
};

let territorioTextStyleFn = function(feature, res) {
  let e = estilos.territorio.text;
  //if (res < 140) {
    let zIndex = 0;
    let territorio = feature.values_.territorio;
    let territorioTextStyle = new Style({
      text: territorioText(feature, res),
      zIndex: zIndex,
    });
    return territorioTextStyle;
  //}
};

let getVeredaText = function(feature) {
  return mayusInicial(feature.values_.VEREDA).replace(/ \(.*\)/,'');
};

let veredaText = function(feature, res) {
  let e = estilos.vereda.text;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let vereda = feature.values_.vereda;
    let font = e.font;
    let trans = e.transform[vereda] || {};
    let offsetX = trans.offsetX || e.offsetXDefault;
    let offsetY = trans.offsetY || e.offsetYDefault;
    let overflow = e.overflow;
    if (res >= e.maxResolutionOverflow) {
      overflow = false;
    }
    if (res >= e.maxResolutionPequeno) {
      font = e.fontPequeno;
    }
    return new Text({
      text: getVeredaText(feature),
      font: font,
      fill: new Fill({
        color: e.fillColor,
      }),
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      offsetX: offsetX*(RES_BASE/res),
      offsetY: offsetY*(RES_BASE/res),
      overflow: overflow,
    })
  }
};

let veredaStyleFn = function(feature, res) {
  let e = estilos.vereda;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let veredaStyle = new Style({
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
    });
    return veredaStyle;
  }
};

let veredaTextStyleFn = function(feature, res) {
  let veredaTextStyle = new Style({
    text: veredaText(feature, res),
  });
  return veredaTextStyle;
};

let drenajeLineaStyleFn = function(feature, res) {
  let e = estilos.drenajeLinea;
  if (res <= e.maxResolution) {
    let e = estilos.drenajeLinea;
    let drenajeLineaStyle = new Style({
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidthBase+0.06*(RES_BASE/res),
      }),
    });
    return drenajeLineaStyle;
  }
};

let getDrenajeAreaText = function(feature) {
  if (feature.values_.nombre.startsWith('Quebrada')) {
    return '';
  }
  return feature.values_.nombre;
};

let getParamoText = function(feature) {
  return `Páramo\n${feature.values_.COMPLEJO}`;
};

let paramoText = function(feature, res) {
  let e = estilos.paramo.text;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let overflow = false;
    if (res <= e.maxResolutionOverflow) {
      overflow = true;
    }
    return new Text({
      text: getParamoText(feature),
      font: e.font,
      fill: new Fill({
        color: e.fillColor,
      }),
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      offsetY: e.offsetY*(RES_BASE/res),
      overflow: overflow,
    })
  }
};

let paramoStyleFn = function(feature, res) {
  let e = estilos.paramo;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let paramoStyle = new Style({
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      fill: new Fill({
        color: e.fillColor,
      }),
    });
    return paramoStyle;
  }
};

let paramoTextStyleFn = function(feature, res) {
  let paramoTextStyle = new Style({
    text: paramoText(feature, res),
  });
  return paramoTextStyle;
};

let getParqueText = function(feature) {
  return feature.values_.nombre;
};

let parqueText = function(feature, res) {
  let e = estilos.parque.text;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let overflow = false;
    if (res <= e.maxResolutionOverflow) {
      overflow = true;
    }
    return new Text({
      text: getParqueText(feature),
      font: e.font,
      fill: new Fill({
        color: e.fillColor,
      }),
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      offsetX: e.offsetX*(RES_BASE/res),
      offsetY: e.offsetY*(RES_BASE/res),
      overflow: overflow,
    })
  }
};

let parqueStyleFn = function(feature, res) {
  let e = estilos.parque;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let parqueStyle = new Style({
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      fill: new Fill({
        color: e.fillColor,
      }),
    });
    return parqueStyle;
  }
};

let parqueTextStyleFn = function(feature, res) {
  let parqueTextStyle = new Style({
    text: parqueText(feature, res),
  });
  return parqueTextStyle;
};

let getReservaText = function(feature) {
  return feature.values_.nombre;
};

let reservaText = function(feature, res) {
  let e = estilos.reserva.text;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let offsetY = e.offsetYNormalFont;
    let overflow = false;
    if (res <= e.maxResolutionOverflow) {
      overflow = true;
    }
    let font = e.fontNormal;
    if (res <= e.maxResolutionBigFont) {
      font = e.fontBig;
      offsetY = e.offsetYBigFont;
    }
    return new Text({
      text: getReservaText(feature),
      font: font,
      fill: new Fill({
        color: e.fillColor,
      }),
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      offsetY: offsetY*(RES_BASE/res),
      overflow: overflow,
    })
  }
};

let reservaIconStyleFn = function(feature, res) {
  let e = estilos.reserva.icon;
  if (res <= e.maxResolutionSmallIcon || e.mostrarSiempre) {
    let scale = 0.25;
    let trans = e.transform[feature.values_.nombre] || {};
    let offsetY = trans.offsetYSmallIcon || e.offsetYSmallIcon;
    if (res <= e.maxResolutionNormalIcon || e.mostrarSiempre) {
      scale = 0.7;
      offsetY = e.offsetYNormalIcon;
    }
    if (res <= e.maxResolutionBigIcon) {
      scale = 1;
      offsetY = e.offsetYBigIcon;
    }
    const icon = new Icon({
        src: './icons/camara.svg',
        displacement: [0, offsetY*(RES_BASE/res)*(1/scale)],
        opacity: 1,
        scale: scale,
    })
    let reservaPointStyle = new Style({
      image: icon,
      overflow: e.overflow,
    });
    return reservaPointStyle;
  }
};

let reservaStyleFn = function(feature, res) {
  let e = estilos.reserva;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    if (feature.getGeometry().getType() === 'Point') {
      if (res <= e.icon.maxResolutionSmallIcon) {
        let scale = 0.25;
	let offsetY = e.icon.offsetYSmallIcon;
        if (res <= e.icon.maxResolutionNormalIcon) {
          scale = 0.7;
	}
        if (res <= e.icon.maxResolutionBigIcon) {
          scale = 1;
	  offsetY = e.icon.offsetYBigIcon;
        }
        const icon = new Icon({
            src: './icons/camara.svg',
            displacement: [0, offsetY*(RES_BASE/res)*(1/scale)],
            opacity: 1,
            scale: scale,
        })
        let reservaPointStyle = new Style({
          image: icon,
          overflow: e.overflow,
        });
        return reservaPointStyle;
      }
    } else {
      let reservaStyle = new Style({
        stroke: new Stroke({
          color: e.strokeColor,
          width: e.strokeWidth,
        }),
        fill: new Fill({
          color: e.fillColor,
        }),
      });
      return reservaStyle;
    }
  }
};

let reservaTextStyleFn = function(feature, res) {
  let e = estilos.reserva;
  let reservaTextStyle = new Style({
    text: reservaText(feature, res),
    geometry: e.geometry(feature),
  });
  return reservaTextStyle;
};

let getCentroPobladoText = function(feature) {
  let descripcion = feature.values_.nombre.replace(/ \(.*\)/,'');
  return descripcion;
};

let centroPobladoText = function(feature, res) {
  let e = estilos.centroPoblado.text;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let font = e.font;
    if (res > e.maxResolution && e.mostrarSiempre) {
      font = e.fontPequeno;
    }
    let descripcion = feature.values_.descripcion;
    let trans = e.transform[descripcion] || {};
    let offsetX = trans.offsetX || e.offsetXDefault;
    let offsetY = trans.offsetY || e.offsetYDefault;
    let textAlign = trans.textAlign || e.textAlignDefault;
    return new Text({
      text: getCentroPobladoText(feature),
      font: font,
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      fill: new Fill({
        color: e.fillColor,
      }),
      textAlign: textAlign,
      offsetX: offsetX*(RES_BASE/res),
      offsetY: offsetY*(RES_BASE/res),
      overflow: e.overflow,
    })
  }
};

let centroPobladoStyleFn = function(feature, res) {
  let e = estilos.centroPoblado;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let trans = e.transparency;
    if (res <= e.maxResolutionGrande) {
      trans = e.transparencyGrande;
    }
    let centroPobladoStyle = new Style({
      stroke: new Stroke({
          color: e.strokeColor,
          width: e.strokeWidth,
      }),
      fill: new Fill({
          color: e.fillColorFn(trans),
      }),
    });
    return centroPobladoStyle;
  }
};

let centroPobladoTextStyleFn = function(feature, res) {
  let e = estilos.centroPoblado;
  if (res < 30) {
    let centroPobladoTextStyle = new Style({
      text: centroPobladoText(feature, res),
      geometry: e.geometry(feature),
    });
    return centroPobladoTextStyle;
  }
};

let fondoLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3857,
    url: './data/colombia.json',
    projection: EPSG_3857,
  }),
  style: fondoStyleFn,
  fuente: 'Instituto Geográfico Agustín Codazzi',
})

let sateliteMundoTileLayer = new TileLayer({
  source: new XYZSource({
    url: 'https://{a-h}.tiles.proyectonasa.com/satelite/{z}/{x}/{y}.jpg',
    maxZoom: 15,
    minZoom: 0,
    projection: EPSG_3857,
    cacheSize: 65536,
  }),
  name: 'Satelite',
  fuente: 'Sentinel-2, publicado por la Comisión Europea',
  estilos: estilosOscuro,
})

let viaStyleFn = function(feature, res) {
  let e = estilos.via;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let highway = feature.values_.highway;
    if (highway == 'residential' || highway == 'unclassified') {
      let e = estilos.viaUnclassified;
      let viaStyle = new Style({
        stroke: new Stroke({
          color: e.strokeColor,
          width: e.strokeWidth,
        }),
      });
      return viaStyle;
    }
    let viaStyle = new Style({
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
    });
    return viaStyle;
  }
};

let osmTileLayer = new TileLayer({
  source: new XYZSource({
    url: 'static/osm_2021_3857/{z}/{x}/{y}.png',
    maxZoom: 15,
    minZoom: 0,
    projection: EPSG_3857,
    cacheSize: 65536,
  }),
  name: 'Calles',
  fuente: 'OpenStreetMap',
  estilos: estilosClaro,
})

let relieveMundoTileLayer = new TileLayer({
  source: new XYZSource({
    url: 'static/terrain_3857/{z}/{x}/{y}.png',
    maxZoom: 15,
    minZoom: 0,
    projection: EPSG_3857,
  }),
  name: 'Relieve',
  fuente: 'OpenStreetMap',
  estilos: estilosOscuro,
})

let relieveMundoClaroTileLayer = new TileLayer({
  source: new XYZSource({
    url: 'static/terrain-light_3857/{z}/{x}/{y}.png',
    maxZoom: 15,
    minZoom: 0,
    projection: EPSG_3857,
  }),
  name: 'Relieve (Claro)',
  fuente: 'OpenStreetMap',
  estilos: estilosClaro,
})

let nocheTileLayer = new TileLayer({
  source: new XYZSource({
    url: 'static/black-marble_3857/{z}/{x}/{y}.png',
    maxZoom: 15,
    minZoom: 0,
    projection: EPSG_3857,
  }),
  name: 'Noche',
  fuente: 'Black Marble © NASA Earth Observatory',
  estilos: estilosOscuro,
})

let colombiaLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3857,
    url: './data/colombia.json',
  }),
  style: colombiaStyleFn,
  getStyles: function() { return estilos.colombia },
  name: 'Colombia',
  fuente: 'Instituto Geográfico Agustín Codazzi',
})


let departamentoTextLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3857,
    url: './data/departamento.json',
  }),
  style: departamentoTextStyleFn,
  getStyles: function() { return estilos.departamento.text },
  name: 'Departamentos Etiquetas',
  fuente: 'Instituto Geográfico Agustín Codazzi',
})

var departamentoSource = new VectorSource();
var departamentoBufferSource = new VectorSource();
fetch('./data/departamento.json')
  .then(function (response) {
    return response.json();
  })
  .then(function (json) {
    var format = FORMAT_GEOJSON_3857;
    var features = format.readFeatures(json, {
      featureProjection: 'EPSG:3857',
    });

    var parser = new jsts.io.OL3Parser();
    window.parser = parser;
    parser.inject(
      Point,
      LineString,
      LinearRing,
      Polygon,
      MultiPoint,
      MultiLineString,
      MultiPolygon
    );

    let bufferFeatures = [];
    for (let i = 0; i < features.length; i++) {
      let feature = features[i];
      let jstsGeom = parser.read(feature.getGeometry());

      // crear buffer de 1200 metros adentro de cada poligono
      let buffered = jstsGeom.buffer(-1200);

      let bufferFeature = feature.clone();
      bufferFeature.setGeometry(parser.write(buffered));
      bufferFeatures.push(bufferFeature);
    }

    departamentoSource.addFeatures(features);
    departamentoBufferSource.addFeatures(bufferFeatures);
  });


let departamentoLayer = new VectorLayer({
  source: departamentoSource,
  style: departamentoStyleFn,
  getStyles: function() { return estilos.departamento },
  name: 'Departamentos',
  fuente: 'Instituto Geográfico Agustín Codazzi',
  textLayers: [departamentoTextLayer],
  relleno: true,
})

let departamentoBufferLayer = new VectorLayer({
  source: departamentoBufferSource,
  style: departamentoBufferStyleFn,
  getStyles: function() { return estilos.departamento },
  name: 'Departamentos',
  fuente: 'Instituto Geográfico Agustín Codazzi',
})


let municipioSource = new VectorSource({
    format: FORMAT_GEOJSON_3857,
    url: './data/municipio.json',
});


let municipioTextLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3857,
    url: './data/municipio.json',
  }),
  style: municipioTextStyleFn,
  getStyles: function() { return estilos.municipio.text },
  name: 'Municipios Etiquetas',
  fuente: 'Instituto Geográfico Agustín Codazzi',
})

let municipioLayer = new VectorLayer({
  source: municipioSource,
  style: municipioStyleFn,
  getStyles: function() { return estilos.municipio },
  name: 'Municipios',
  fuente: 'Instituto Geográfico Agustín Codazzi',
  textLayers: [municipioTextLayer],
  relleno: true,
  legendItem: {
    iconText: '-',
    label: 'Límite municipal',
  },
})

let municipioFeaturesScaled = [];

let territorioTextLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/territorio.json',
  }),
  style: territorioTextStyleFn,
  getStyles: function() { return estilos.territorio.text },
  name: 'Territorios Etiquetas',
  fuente: 'Proyecto Nasa',
  declutter: true,
})

let territorioLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/territorio.json',
  }),
  style: territorioStyleFn,
  getStyles: function() { return estilos.territorio },
  name: 'Territorios',
  fuente: 'Proyecto Nasa',
  textLayers: [territorioTextLayer],
  relleno: true,
  legendItem: {
    iconText: '-',
    label: 'Límite de resguardo',
  },
})

let veredaTextLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/vereda_datos_basicos.json',
  }),
  style: veredaTextStyleFn,
  getStyles: function() { return estilos.vereda.text },
  name: 'Veredas Etiquetas',
  fuente: 'Proyecto Nasa',
  declutter: true,
})

let veredaLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/vereda_datos_basicos.json',
  }),
  style: veredaStyleFn,
  getStyles: function() { return estilos.vereda },
  name: 'Veredas',
  fuente: 'Proyecto Nasa',
  textLayers: [veredaTextLayer],
  legendItem: {
    iconText: '-',
    label: 'Límite de vereda',
  },
})

let drenajeLineaLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/drenaje_linea.json',
  }),
  style: drenajeLineaStyleFn,
  getStyles: function() { return estilos.drenajeLinea },
  name: 'Ríos',
  nombreParaAtributos: 'Río',
  fuente: 'OpenStreetMap',
  mostrarAtributos: true,
  legendItem: {
    iconText: '-',
    label: 'Río o quebrada',
  },
})

let paramoTextLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/paramo.json'
  }),
  style: paramoTextStyleFn,
  getStyles: function() { return estilos.paramo.text },
  name: 'Paramos Etiquetas',
  fuente: 'Instituto Geográfico Agustín Codazzi',
})

let paramoLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/paramo.json'
  }),
  style: paramoStyleFn,
  getStyles: function() { return estilos.paramo },
  name: 'Páramos',
  fuente: 'Instituto Geográfico Agustín Codazzi',
  textLayers: [paramoTextLayer],
  mostrarAtributos: true,
  legendItem: {
    iconHtml: '<div class="icon-box"></div>',
    label: 'Páramos',
    bgColor: 'rgb(154, 183, 144)',
  },
})

let parqueTextLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/parque.json'
  }),
  style: parqueTextStyleFn,
  getStyles: function() { return estilos.parque.text },
  name: 'Parques Etiquetas',
  fuente: 'Instituto Geográfico Agustín Codazzi',
})

let parqueLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/parque.json'
  }),
  style: parqueStyleFn,
  getStyles: function() { return estilos.parque },
  name: 'Parques',
  fuente: 'Instituto Geográfico Agustín Codazzi',
  textLayers: [parqueTextLayer],
  mostrarAtributos: true,
  legendItem: {
    iconHtml: '<div class="icon-box"></div>',
    label: 'Parques',
    bgColor: 'rgb(26, 196, 26)',
  },
})

let reservaTextLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/reserva.json'
  }),
  style: reservaTextStyleFn,
  getStyles: function() { return estilos.reserva.text },
  name: 'Reservas Etiquetas',
  fuente: 'Proyecto nasa',
})

let reservaIconSource = new VectorSource({
  format: FORMAT_GEOJSON_3115,
});

let reservaIconLayer = new VectorLayer({
  source: reservaIconSource,
  style: reservaIconStyleFn,
  getStyles: function() { return estilos.reserva.icon },
  name: 'Espacios de vida íconos',
  fuente: 'Proyecto Nasa',
});

let reservaSource = new VectorSource({
  format: FORMAT_GEOJSON_3115,
  url: './data/reserva.json'
});

let reservaLayer = new VectorLayer({
  source: reservaSource,
  style: reservaStyleFn,
  getStyles: function() { return estilos.reserva },
  name: 'Espacios de vida',
  fuente: 'Proyecto Nasa',
  textLayers: [reservaTextLayer],
  iconLayers: [reservaIconLayer],
  mostrarAtributos: true,
  legendItem: {
    iconHtml: '<div class="icon-box"></div>',
    label: 'Espacios de vida',
    bgColor: 'rgb(77, 106, 169)',
  },
})
reservaSource.on('featuresloadend', function(evt) {
  let features = reservaSource.getFeatures()
  for(let i=0; i < features.length; i++) {
    let feature = features[i];
    if (feature.values_.fotos) {
      let geom = feature.getGeometry();
      let centroid = getCenter(geom.getExtent());
      var centroidFeature = new Feature({
          geometry: new Point(centroid),
      });
      centroidFeature.set('fotos', feature.values_.fotos);
      centroidFeature.set('nombre', feature.values_.nombre);
      reservaIconSource.addFeature(centroidFeature);
    }
  };
  reservaIconSource.changed();
});

let getRiegoText = function(feature) {
  let nombre = feature.values_.nombre;
  if (nombre == 'Sistema de riego') {
    return 'Riego';
  }
  return nombre;
};

let riegoText = function(feature, res) {
  let e = estilos.riego.text;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let font = e.fontNormal;
    if (res <= e.maxResolutionBigFont) {
      font = e.fontBig;
    }
    return new Text({
      text: getRiegoText(feature),
      font: font,
      fill: new Fill({
        color: e.fillColor,
      }),
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      textBaseline: e.textBaseline,
      offsetY: e.offsetY,
      overflow: e.overflow,
    })
  }
};

let riegoStyleFn = function(feature, res) {
  let e = estilos.riego;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let riegoLineaStyle = new Style({
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
    });
    let riegoLineaStyleBuffer = new Style({
      stroke: new Stroke({
        color: e.bufferStrokeColor,
        width: e.strokeWidth*2,
      }),
    });
    return [riegoLineaStyleBuffer, riegoLineaStyle];
  }
};

let riegoIconStyleFn = function(feature, res) {
  let e = estilos.riego.icon;
  if (res <= e.maxResolutionSmallIcon || e.mostrarSiempre) {
    let scale = 0.25;
    let trans = e.transform[feature.values_.nombre] || {};
    let offsetY = trans.offsetYSmallIcon || e.offsetYSmallIcon;
    if (res <= e.maxResolutionNormalIcon || e.mostrarSiempre) {
      scale = 0.7;
    }
    if (res <= e.maxResolutionBigIcon) {
      scale = 1;
      offsetY = e.offsetYBigIcon;
    }
    const icon = new Icon({
        src: './icons/camara.svg',
        displacement: [0, offsetY*(RES_BASE/res)*(1/scale)],
        opacity: 1,
        scale: scale,
    })
    let riegoPointStyle = new Style({
      image: icon,
      overflow: e.overflow,
    });
    return riegoPointStyle;
  }
};


let riegoTextStyleFn = function(feature, res) {
  let e = estilos.riego;
  let riegoTextStyle = new Style({
    text: riegoText(feature, res),
    overflow: e.overflow,
    geometry: e.geometry(feature),
  });
  return riegoTextStyle;
};

let riegoTextLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3857,
    url: './data/riego.json',
  }),
  style: riegoTextStyleFn,
  getStyles: function() { return estilos.riego.text },
  name: 'Sistemas de riego etiquetas',
  declutter: true,
})

let riegoIconSource = new VectorSource({
  format: FORMAT_GEOJSON_3115,
});

let riegoIconLayer = new VectorLayer({
  source: riegoIconSource,
  style: riegoIconStyleFn,
  getStyles: function() { return estilos.riego.icon },
  name: 'Sistema de riego íconos',
  fuente: 'Proyecto Nasa',
});

let riegoSource = new VectorSource({
  format: FORMAT_GEOJSON_3115,
  url: './data/riego.json'
});

riegoSource.on('featuresloadend', function(evt) {
  let features = riegoSource.getFeatures()
  for(let i=0; i < features.length; i++) {
    let feature = features[i];
    if (feature.values_.fotos) {
      let geom = feature.getGeometry();
      let centroid = getCenter(geom.getExtent());
      var centroidFeature = new Feature({
          geometry: new Point(centroid),
      });
      centroidFeature.set('fotos', feature.values_.fotos);
      centroidFeature.set('nombre', feature.values_.nombre);
      riegoIconSource.addFeature(centroidFeature);
    }
  };
  riegoIconSource.changed();
});

let riegoLayer = new VectorLayer({
  source: riegoSource,
  style: riegoStyleFn,
  getStyles: function() { return estilos.riego },
  name: 'Sistemas de riego',
  fuente: 'Proyecto Nasa',
  textLayers: [riegoTextLayer],
  iconLayers: [riegoIconLayer],
  mostrarAtributos: true,
  legendItem: {
    iconText: '-',
    label: 'Sistema de riego',
  },
})

let getBeneficiarioWFPText = function(feature) {
  return '' + feature.values_["Total beneficiarios"];
};

let beneficiarioWFPText = function(feature, res) {
  let e = estilos.beneficiarioWFP.text;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    return new Text({
      text: getBeneficiarioWFPText(feature),
      font: e.font,
      fill: new Fill({
        color: e.fillColor,
      }),
      stroke: new Stroke({
        color: e.strokeColor,
        width: e.strokeWidth,
      }),
      textBaseline: e.textBaseline,
      overflow: e.overflow,
      offsetY: e.offsetY,
    })
  }
};

let beneficiarioWFPStyleFn = function(feature, res) {
  let e = estilos.beneficiarioWFP;
  if (res <= e.maxResolution || e.mostrarSiempre) {
    let image = new CircleStyle ({
      stroke: new Stroke({
          color: e.strokeColor,
          width: e.strokeWidth,
      }),
      fill: new Fill({
          color: e.fillColor,
      }),
      radius: e.radius,
    });
    let beneficiarioWFPStyle = new Style({
      image: image,
      overflow: e.overflow,
      offsetY: e.offsetY,
    });
    return beneficiarioWFPStyle;
  }
};

let beneficiarioWFPTextStyleFn = function(feature, res) {
  let e = estilos.beneficiarioWFP;
  let beneficiarioWFPTextStyle = new Style({
    text: beneficiarioWFPText(feature, res),
    overflow: e.overflow,
  });
  return beneficiarioWFPTextStyle;
};

let beneficiarioWFPSource = new VectorSource({
  format: FORMAT_GEOJSON_3857,
  url: './data/beneficiario_pma.json',
});

let beneficiarioWFPTextLayer = new VectorLayer({
  source: beneficiarioWFPSource,
  style: beneficiarioWFPTextStyleFn,
  getStyles: function() { return estilos.beneficiarioWFP.text },
  name: 'Beneficiarios WFP Etiquetas',
  declutter: true,
})

let beneficiarioWFPLayer = new VectorLayer({
  source: beneficiarioWFPSource,
  style: beneficiarioWFPStyleFn,
  getStyles: function() { return estilos.beneficiarioWFP },
  name: 'Beneficiarios WFP',
  fuente: 'Proyecto Nasa',
  nombreParaAtributos: 'Beneficiarios WFP',
  textLayers: [beneficiarioWFPTextLayer],
  mostrarAtributos: true,
  legendItem: {
    iconText: '⬤',
    iconFontSize: '16px',
    extraClass: 'stroked-text',
    label: 'Beneficiarios WFP',
  },
})

beneficiarioWFPSource.on('featuresloadend', function(evt) {
  let features = beneficiarioWFPSource.getFeatures()
  for(let i=0; i < features.length; i++) {
    let feature = features[i];
    feature.getGeometry().translate(0, -300);
  }
  beneficiarioWFPSource.changed();
});

let centroPobladoTextLayer = new VectorLayer({
  source: new VectorSource({
    format: new GeoJSON({
      dataProjection: EPSG_3115,
      featureProjection: EPSG_3115,
      geometryName: 'point',
    }),
    url: './data/centro_poblado.json',
  }),
  style: centroPobladoTextStyleFn,
  getStyles: function() { return estilos.centroPoblado.text },
  name: 'Centros Poblados Etiquetas',
  fuente: 'Proyecto Nasa',
  declutter: true,
})

let centroPobladoLayer = new VectorLayer({
  source: new VectorSource({
    format: new GeoJSON({
      dataProjection: EPSG_3115,
      featureProjection: EPSG_3115,
      geometryName: 'point',
    }),
    url: './data/centro_poblado.json',
  }),
  style: centroPobladoStyleFn,
  getStyles: function() { return estilos.centroPoblado },
  name: 'Centros Poblados',
  fuente: 'Proyecto Nasa',
  textLayers: [centroPobladoTextLayer],
  mostrarAtributos: true,
})


let viaLayer = new VectorLayer({
  source: new VectorSource({
    format: FORMAT_GEOJSON_3115,
    url: './data/via.json',
  }),
  style: viaStyleFn,
  getStyles: function() { return estilos.via },
  name: 'Carreteras',
  nombreParaAtributos: 'Carretera',
  fuente: 'OpenStreetMap',
  mostrarAtributos: true,
  legendItem: {
    iconText: '-',
    label: 'Carretera',
  },
})

fondoLayer.set('orden', 0);

// Capas bases, nivel mundo. Sólo se puede habilitar una a la vez
let baseMundoLayers = [
  [sateliteMundoTileLayer, !LITE_MODE],
  [relieveMundoTileLayer, false],
  //[relieveMundoClaroTileLayer, false],
  [osmTileLayer, false],
  //[nocheTileLayer, false],
];
baseMundoLayers.forEach(l => l[0].set('orden', 1));

// Capas bases, nivel local. Sólo se puede habilitar una a la vez
let baseLocalLayers = [
];
baseLocalLayers.forEach(l => l[0].set('orden', 2));

let vectorLayers = [
  [drenajeLineaLayer, true],
  [municipioLayer, !LITE_MODE],
  [colombiaLayer, true],
  [departamentoBufferLayer, true],
  [departamentoLayer, !LITE_MODE],
  [veredaLayer, true],
  [territorioLayer, true],
  [viaLayer, true],
  [paramoLayer, false],
  [parqueLayer, false],
  [reservaLayer, true],
  [riegoLayer, true],
  [beneficiarioWFPLayer, false],
  // NOTA: Capa de centros poblados es muy incompleto. Deshabilitado.
  //[centroPobladoLayer, true],
];
vectorLayers.forEach((l, i) => l[0].set('orden', i+3));

let textLayersAll = [
  // Capas de etiquetas
  [departamentoTextLayer, !LITE_MODE],
  [municipioTextLayer, !LITE_MODE],
  [territorioTextLayer, true],
  [veredaTextLayer, true],
  [paramoTextLayer, false],
  [parqueTextLayer, false],
  [reservaTextLayer, true],
  [riegoTextLayer, true],
  [beneficiarioWFPTextLayer, false],
  // NOTA: Capa de centros poblados es muy incompleto. Deshabilitado.
  //[centroPobladoTextLayer, true],
];
textLayersAll.forEach((l, i) => l[0].set('orden', i+vectorLayers.length+2));

let iconLayersAll = [
  // Capas de íconos
  [reservaIconLayer, true],
  [riegoIconLayer, true],
];

let initialLayers = Array.prototype.concat(
  [fondoLayer],
  baseMundoLayers.filter(l => l[1]).map(l => l[0]),
  baseLocalLayers.filter(l => l[1]).map(l => l[0]),
  vectorLayers.filter(l => l[1]).map(l => l[0]),
  textLayersAll.filter(l => l[1]).map(l => l[0]),
  iconLayersAll.filter(l => l[1]).map(l => l[0]),
);

window.map = new Map({
  target: 'map',
  layers: initialLayers,
  view: new View({
    center: MAP_CENTER_3857,
    resolution: calcResInicial(),
    projection: EPSG_3857,
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM,
    zoomFactor: ZOOM_FACTOR,
  }),
  interactions: interaction_defaults({
    dragPan: false,
    mouseWheelZoom: false,
    keyboardZoom: false
  }).extend([
    new DragPan({kinetic: false}),
    new MouseWheelZoom({duration: 150}),
    new KeyboardZoom({duration: 250})
  ]),
  controls: defaults().extend([new FullScreen()]),
});

function updateGalleryButton(button, idx, hiddenIdx) {
  if (idx == hiddenIdx) {
    button.style.visibility = "hidden";
  } else {
    button.style.visibility = "visible";
  }
}

function parseXmp(data) {
  let metadata={}
  let attrs = data.children[0].childNodes[1];
  //let nodes1 = attrs.childNodes;
  //let node1;
  //for (let i = 0; i < nodes1.length; i++) {
  //  node1 = nodes1[i];
  //  if ('attributes' in node1 && "xmlns:tiff" in node1.attributes) {
  //    break;
  //  }
  //}
  let nodes = attrs.childNodes;
  let node;
  for (let i = 0; i < nodes.length; i++) {
    node = nodes[i];
    if (node.nodeName == "rdf:Description") {
      for (let j = 0; j < nodes.length; j++) {
        let childNode = node.childNodes[j];
        if (childNode && childNode.nodeName == "dc:description") {
          metadata["Description"] = childNode.children[0].textContent.trim();
	} else if (childNode && childNode.nodeName == "exif:DateTimeOriginal") {
          metadata["DateTimeOriginal"] = childNode.textContent.trim();
          console.log(metadata["DateTimeOriginal"]);
	}
      }
    }
  }
  return metadata;
}

async function updateImgDescription(imgUrl) {
  let metadata;
  if (METADATA_SRC_FOR_IMG) {
    // We can load and parse a small auxilliary file for the metadata in case
    // the HEIF parser fails for our AVIF images.
    // Problems have been observed with certain AVIF images and the HEIF parser
    // fails with "Malformed EXIF data".
    // TODO: Fix HEIF parser
    imgUrl = imgUrl.replace('.avif', '.' + METADATA_SRC_FOR_IMG);
  }
  if (METADATA_SRC_FOR_IMG.toLocaleLowerCase() == 'xmp') {
    let xmlParser = new window.DOMParser()
    metadata = await fetch(imgUrl, { cache: 'force-cache' })
      .then(response => response.text())
      .then(str => xmlParser.parseFromString(str, "text/xml"))
      .then(data => parseXmp(data) );
  } else {
    metadata = await exifr.parse(imgUrl, ['Description']);
  }
  if (metadata) {
    if ('errors' in metadata) {
      console.log('failed to parse metadata')
      console.log(metadata['errors'][0])
    } else {
      let galleryImgDescription = document.getElementById("galleryimgdescription");
      let date = metadata['DateTimeOriginal'];
      if (date.endsWith('-01-01T00:00:00')) {
	date = date.slice(0,4);
      }
      galleryImgDescription.textContent = metadata['Description'] + '. ' + date.slice(0,10);
    }
  } else {
    console.log('failed to parse metadata with unknown error')
  }
}

function openGallery(evt) {
  // photoUrls argument is optional
  // Normally we get the URLs from the dataset of the clicked element.
  // However for testing we can call the function directly with a list of URLs.
  let photoUrlsArr = '';
  if (evt.target) {
    photoUrlsArr = this.dataset.photoUrls.split(',');
  } else {
    photoUrlsArr = evt;
  }

  let galleryModal = document.getElementById("gallerymodal");
  galleryModal.style['display'] = 'flex';

  // Set initial image
  let galleryImg = document.getElementById("galleryimg");
  let galleryImgDescription = document.getElementById("galleryimgdescription");
  galleryImg.src = photoUrlsArr[0];
  updateImgDescription(galleryImg.src);

  // Asyncronously fetch remaining images
  photoUrlsArr.forEach(imgUrl => {
    new Image().src = imgUrl;
    if (METADATA_SRC_FOR_IMG) {
      let metadataUrl = imgUrl.replace('.avif', '.' + METADATA_SRC_FOR_IMG);
      fetch(metadataUrl, { cache: 'force-cache' });
    }
  });

  // Handle next/previous buttons
  // TODO: Unbind listeners when gallery is closed
  let photoIdx = 0;
  let buttonGalleryNext = document.getElementById("gallerynext");
  let nextHandler = () => {
    photoIdx = Math.min(photoIdx + 1, photoUrlsArr.length - 1);
    galleryImg.src = photoUrlsArr[photoIdx];
    updateImgDescription(galleryImg.src);
    updateGalleryButton(buttonGalleryPrev, photoIdx, 0);
    updateGalleryButton(buttonGalleryNext, photoIdx, photoUrlsArr.length - 1);
  };
  buttonGalleryNext.addEventListener('click', nextHandler);

  let buttonGalleryPrev = document.getElementById("galleryprev");
  let prevHandler = () => {
    photoIdx = Math.max(photoIdx - 1, 0);
    galleryImg.src = photoUrlsArr[photoIdx];
    updateImgDescription(galleryImg.src);
    updateGalleryButton(buttonGalleryPrev, photoIdx, 0);
    updateGalleryButton(buttonGalleryNext, photoIdx, photoUrlsArr.length - 1);
  }
  buttonGalleryPrev.addEventListener('click', prevHandler);

  let buttonGalleryClose = document.getElementById("galleryclose");
  buttonGalleryClose.addEventListener('click', function() {
    galleryModal.style.display = "none";
    galleryImg.src = '';
    buttonGalleryNext.removeEventListener("click", nextHandler);
    buttonGalleryPrev.removeEventListener("click", prevHandler);
  });

  window.addEventListener('click', function(evt) {
    if (evt.target == galleryModal) {
      galleryModal.style.display = "none";
      galleryImg.src = '';
      buttonGalleryNext.removeEventListener("click", nextHandler);
      buttonGalleryPrev.removeEventListener("click", prevHandler);
    }
  });

  updateGalleryButton(buttonGalleryPrev, photoIdx, 0);
  updateGalleryButton(buttonGalleryNext, photoIdx, photoUrlsArr.length - 1);
}

window.map.on('click', function(evt) {
  let pixel = evt.pixel;
  let attrDiv = document.getElementById('atributos');
  let features = [];
  let layers = [];

  // Open gallery if topmost feature is a photo icon
  let feature = map.forEachFeatureAtPixel(pixel, (feature, layer) => {
    return feature;
  });
  if (feature && feature.getGeometry().getType() == 'Point') {
    if ('fotos' in feature.values_) {
      openGallery(feature.values_.fotos.split(','));
      return;
    }
  }

  map.forEachFeatureAtPixel(pixel, (feature, layer) => {
    features.push(feature);
    layers.push(layer);
  });
  let featureVals;
  let layerVals;

  for (let i = 0; i < layers.length; i++) {
    if (layers[i].values_.mostrarAtributos) {
      feature = features[i];
      featureVals = feature.values_;
      layerVals = layers[i].values_;
    }
  }

  // Usar coordenadas desde feature para Point, y no coordenadas de clic
  let coord;
  if (feature && feature.getGeometry().getType() == 'Point') {
    coord = feature.getGeometry().getCoordinates();
    coord = transform(coord, 'EPSG:3857', 'EPSG:4326');
    coord = [coord[0].toFixed(6), coord[1].toFixed(6)]
  } else {
    coord = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
    coord = [coord[0].toFixed(6), coord[1].toFixed(6)]
  }

  // Territorio, vereda
  let territorios = territorioLayer.getSource().getFeaturesAtCoordinate(evt.coordinate);
  let veredas = veredaLayer.getSource().getFeaturesAtCoordinate(evt.coordinate);

  // Construir html para mostrar en panel de atributos
  let attrHtml = '';
  if (featureVals) {
    if (featureVals.nombre) {
      attrHtml += `<p class="atributos-titulo">${featureVals.nombre}</p>`;
    } else if (layerVals.nombreParaAtributos) {
      attrHtml += `<p class="atributos-titulo">${layerVals.nombreParaAtributos}</p>`;
    }
  }
  if (territorios.length > 0 && veredas.length > 0) {
    let territorio = territorios[0].values_.territorio;
    let vereda = mayusInicial(veredas[0].values_.VEREDA);
    attrHtml += `<p class="atributos-vereda">${vereda}, ${territorio}</p><br>`;
  }
  let photoUrls = [];
  attrHtml += `<dl><dt>Coordenadas:</dt><dd>${coord}</dd>`;
  if (featureVals) {
    for (var k in featureVals) {
      if (!(k in IGNORE_ATTRIBUTES)) {
        if (featureVals[k]) {
          attrHtml += `<dt>${k}:</dt><dd>${featureVals[k]}</dd>`;
        }
      }
      if (k == 'fotos') {
	photoUrls = featureVals[k];
      }
    }
  }
  attrHtml += '</dl>';
  attrDiv.innerHTML = attrHtml;
  if (photoUrls.length > 0) {
    let buttonOpenGallery = document.createElement('button');
    buttonOpenGallery.id = 'galleryopenbutton';
    buttonOpenGallery.className = 'galleryopenbutton';
    buttonOpenGallery.textContent = 'Ver Fotos';
    buttonOpenGallery.dataset.photoUrls = photoUrls;
    buttonOpenGallery.addEventListener('click', openGallery);

    let buttonOpenGalleryBox = document.createElement('div');
    buttonOpenGalleryBox.id = 'galleryopenbutton-box';
    buttonOpenGalleryBox.className = 'galleryopenbutton-box';
    buttonOpenGalleryBox.appendChild(buttonOpenGallery)
    attrDiv.appendChild(buttonOpenGalleryBox)
  }
  let botonCerrar = document.createElement('span');
  botonCerrar.className = 'atributos-cerrar';
  botonCerrar.textContent = '×';
  botonCerrar.addEventListener('click', function() {
    attrDiv.innerHTML = '';
    attrDiv.style = 'padding: 0';
  });
  attrDiv.insertBefore(botonCerrar, attrDiv.firstChild);
  attrDiv.style = 'padding: 10px 0px 10px 10px';
});
    

let tree = new layerTree({
  map: map,
  target: 'layertree',
});
let vectorLayersForMenu = [
  [paramoLayer, false],
  [parqueLayer, false],
  [reservaLayer, true],
  [riegoLayer, true],
  [beneficiarioWFPLayer, false],
  [drenajeLineaLayer, true],
  [viaLayer, true],
  [colombiaLayer, true],
  [departamentoLayer, true],
  [municipioLayer, true],
  [territorioLayer, true],
  [veredaLayer, true],
  // NOTA: Capa de centros poblados es muy incompleto. Deshabilitado.
  //[centroPobladoLayer, true],
];

vectorLayersForMenu.reverse().forEach(l => {
  tree.createRegistry(l[0], l[1]);
});
baseLocalLayers.slice(0).reverse().forEach(l => {
  tree.createRegistry(l[0], l[1]);
});
baseMundoLayers.slice(0).reverse().forEach(l => {
  tree.createRegistry(l[0], l[1]);
});

window.removeAlpha = function(rgba) {
  let re = /^rgba?\(((,?\s*\d+){3}).+$/
  return rgba.replace(re, 'rgb($1)');
}

let debugPanel = document.getElementById('debugpanel');
if (DEBUG_PANEL) {
  debugPanel.style['display'] = 'block';
}
let legendItemsDiv = document.getElementById('legenditems');

let redrawLegend = function() {
  legendItemsDiv.innerHTML = '';
  let res = map.getView().getResolution();
  if (DEBUG_PANEL) {
    debugPanel.textContent = `Res: ${res.toFixed()}`;
  }
  vectorLayersForMenu.slice().reverse().forEach(l => {
    let layer = l[0];
    let active = layer.get('active');
    let legendItem = layer.get("legendItem");
    if (layer.get("legendItem") && active) {
      let styles = layer.get('getStyles')();
      if (res <= styles.maxResolution || styles.mostrarSiempre) {
        let fillColor = styles.fillColor;
        let strokeColor = styles.strokeColor;
        let legendItemEl = document.createElement('p');
        legendItemEl.className = 'legenditem';
        let legendItemInnerEl = document.createElement('span');
        let legendItemIconEl = document.createElement('span');
        legendItemIconEl.className = 'legendicon';
	if (legendItem.iconText) {
	  let iconColor = fillColor;
	  if (legendItem.iconText == '-') {
            iconColor = strokeColor;
	  }
          legendItemIconEl.style['color'] = removeAlpha(iconColor);
	  if (legendItem.iconFontSize) {
            legendItemIconEl.style['font-size'] = legendItem.iconFontSize;
	  }
	  if (legendItem.extraClass) {
            legendItemIconEl.classList.add(legendItem.extraClass);
		  console.log(legendItem.extraClass);
	  }
          legendItemIconEl.textContent = legendItem.iconText;
	} else if (legendItem.iconHtml) {
	  let template = document.createElement('template');
          template.innerHTML = legendItem.iconHtml;
	  let iconEl = template.content.firstChild;
          let borderColor = removeAlpha(strokeColor);
          iconEl.style['border'] = borderColor + 'solid 2px';
          iconEl.style['background-color'] = legendItem.bgColor;
          legendItemIconEl.appendChild(iconEl);
	}
        let legendItemLabelEl = document.createElement('span');
        legendItemLabelEl.textContent = legendItem.label;
        legendItemInnerEl.appendChild(legendItemIconEl);
        legendItemInnerEl.appendChild(legendItemLabelEl);
        legendItemEl.appendChild(legendItemInnerEl);
        legendItemsDiv.appendChild(legendItemEl);
      }
    }
  });
};
var zoom = map.getView().getZoom();
map.on('moveend', function(e) {
  var newZoom = map.getView().getZoom();
  if (zoom != newZoom) {
    zoom = newZoom;
    redrawLegend();
  }
});
redrawLegend();

let welcomeModal = document.getElementById("welcomemodal");
let buttonWelcomeClose = document.getElementById("welcomeclose");
buttonWelcomeClose.addEventListener('click', function() {
  welcomeModal.style.display = "none";
});

window.addEventListener('click', function(evt) {
  if (evt.target == welcomeModal) {
    welcomeModal.style.display = "none";
  }
});

document.getElementById('map').focus();
